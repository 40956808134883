@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Wix Madefor Display", sans-serif;
  font-optical-sizing: auto;
  line-height: 1.5;
  font-weight: 700;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
